import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import NakaLightbox from '../../../components/NakaLightbox'
import Layout from '../../../components/layout'
import WeirdAlMenu from './_menu.js'

const WeirdAl2019TourPage = ({ data }) => (
  <Layout bgClass="clients-weirdal">
    <WeirdAlMenu pageName="2019_strings_attached_tour" />
    <div className="textPageDetails">
      <p>
        The poster series for “Weird Al” Yankovic’s Strings Attached Summer tour. Nakatomi produced 23 posters, each fitting a provided concept to create a diverse collection of “Weird Al” imagery, as well as hand-printing small runs of artist copies.
      </p>
    </div>
    <div className="gallery-container">
      <NakaLightbox images={data.allFile.edges} />
    </div>
  </Layout>
)

WeirdAl2019TourPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default WeirdAl2019TourPage

export const pageQuery = graphql`
  query WeirdAl2019TourPageIndexQuery {
    allFile(
      filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativePath: {regex: "/weirdal/2019_strings_attached_tour/"}}
    	sort: {fields: name}
    ) {
      totalCount
      edges {
        node {
          childImageSharp {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes
            }
            fluid {
              srcSet
              src
              originalName
              presentationWidth
              presentationHeight
              originalImg
            }
          }
        }
      }
    }
  }
`
