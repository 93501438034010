import React from 'react'
import { Link } from 'gatsby'

const WeirdAlMenu = (props) => (
  <div>
  <h2 className="major">"Weird Al" Yankovic</h2>
  <ul className="bigList">
  <li className={props.pageName === '2019_strings_attached_tour' ? 'current' : ''}><Link to="/clients/weirdal/2019_strings_attached_tour">2019 Strings Attached Tour</Link></li>
  </ul>
  </div>
)

export default WeirdAlMenu
